import React, { useState } from "react"
import classnames from "classnames"
import { FaStar } from "react-icons/fa"
type Props = {
  //Insert Props Here
  headline: string
  testimonials: [
    {
      quote: string
      author: string
      stars: number
    }
  ]
  id?: string
  className?: string
}

const RatingTestimonials: React.FC<Props> = ({
  headline,
  testimonials,
  id,
  className,
}) => {
  return (
    <div className={className} id={id}>
      <div className="flex flex-col items-center max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 justify-center">
        <h2 className="text-3xl mb-4 leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          {headline}
        </h2>
        <div className="flex flex-col md:flex-row">
          {testimonials.map((t, index) => {
            let stars = []
            for (let i = 0; i < t.stars; ++i) {
              stars.push(<FaStar key={i} />)
            }

            return (
              <div className="px-4 py-4 flex flex-col items-center">
                <div className="flex text-yellow-500 text-2xl mb-3">
                  {stars}
                </div>
                <blockquote className="text-xl italic quote relative font-serif mb-1">
                  <div
                    style={{ top: "-20px", left: "-10px" }}
                    className="text-5xl absolute left-0 font-bold font-serif top-0 text-gray-400 z-0"
                  >
                    &ldquo;
                  </div>
                  <div className="z-10 relative text-gray-900">{t.quote}</div>
                </blockquote>
                <div className="text-gray-500">- {t.author}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default RatingTestimonials
