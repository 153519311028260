import React from "react"
import classnames from "classnames"

type Props = {
  //Insert Props Here
  headline: string
  description: string
  ctas: [
    {
      label: string
      url: string
    }
  ]
  image?: string
  className?: string
  id?: string
}

const CTASimpleCentered: React.FC<Props> = ({
  headline,
  description,
  ctas,
  image,
  className,
  id,
}) => {
  return (
    <div className={className} id={id}>
      <div className="flex max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 justify-center">
        {/* <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full flex-grow hidden md:block"
          src={image}
          alt=""
        /> */}
        <div
          className={classnames("flex flex-col items-center flex-grow", {
            "md:pl-8": image,
          })}
        >
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {headline}
          </h2>
          <p className="text-base text-gray-500 mt-4 max-w-3xl">
            {description}
          </p>
          <div className="mt-6 flex">
            <div className="inline-flex rounded-md shadow">
              {ctas.map(({ url, label }, index) => {
                return (
                  <a
                    href={url}
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-base hover:bg-primary-dark focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    {label}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTASimpleCentered
