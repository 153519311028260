import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import HeroAngledImage from "../components/hero/HeroAngledImage"
import FeatureGrid from "../components/features/FeatureGrid"
import CTASimpleCentered from "../components/ctas/CTASimpleCentered"
import FooterSimple from "../components/footers/FooterSimple"
import FAQCenteredAccordion from "../components/faqs/FAQCenteredAccordion"
import RatingTestimonials from "../components/ratings/RatingTestimonials"

// Config
import featureConfig from "../pageConfig/features.json"
import heroConfig from "../pageConfig/hero.json"
import submissionConfig from "../pageConfig/submission.json"
import footerConfig from "../pageConfig/footer.json"
import gifJifProConfig from "../pageConfig/gifjifpro.json"
import faqConfig from "../pageConfig/faq.json"
import ratingsConfig from "../pageConfig/ratings.json"

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>GifJif</title>
      <meta
        name="description"
        content="GifJif is the best Gif app in the world"
      />
    </Helmet>
    <HeroAngledImage {...heroConfig} id="hero" />
    <FeatureGrid {...featureConfig} className="bg-gray-100" id="features" />
    <CTASimpleCentered {...submissionConfig} id="submission" />
    <FeatureGrid {...gifJifProConfig} className="bg-gray-100" id="gifjifpro" />
    <FAQCenteredAccordion {...faqConfig} id="faq" />
    <RatingTestimonials
      {...ratingsConfig}
      id="ratings"
      className="bg-gray-100"
    />
  </Layout>
)
