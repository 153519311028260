import React, { useState } from "react"
import classnames from "classnames"
import { FaChevronDown } from "react-icons/fa"
type FAQProps = {
  question: string
  answer: string
  expandedOnLoad?: boolean
}

const FAQ: React.FC<FAQProps> = ({ question, answer, expandedOnLoad }) => {
  const [expanded, setExpanded] = useState(expandedOnLoad)
  return (
    <div
      className="border-t border-b flex w-full py-4 px-3 justify-between items-start cursor-pointer hover:bg-gray-100"
      onClick={() => setExpanded(!expanded)}
    >
      <div>
        <div className="font-medium">{question}</div>
        {expanded && <div className="font-sm text-gray-500 mt-2">{answer}</div>}
      </div>
      <div
        className={classnames("text-gray-500 ease-in-out duration-200", {
          "transform rotate-180": expanded,
        })}
      >
        <FaChevronDown />
      </div>
    </div>
  )
}

type Props = {
  //Insert Props Here
  headline: string
  faq: [
    {
      question: string
      answer: string
    }
  ]
  id?: string
}

const FAQCenteredAccordion: React.FC<Props> = ({ headline, faq, id }) => {
  return (
    <div className="py-12 bg-white" id={id}>
      <div className="flex flex-col max-w-3xl mx-auto py-12 px-0 sm:px-6 lg:py-16 lg:px-8 items-center">
        <h3 className="mt-2 mb-5 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 px-4">
          {headline}
        </h3>
        <div className="border-t border-b w-full">
          {faq.map((faq, index) => {
            return <FAQ {...faq} key={index} expandedOnLoad={index === 0} />
          })}
        </div>
      </div>
    </div>
  )
}

export default FAQCenteredAccordion
