import React, { useState } from "react"
import classnames from "classnames"
import { FaAppStoreIos } from "react-icons/fa"

type Props = {
  //Insert Props Here
  headline: string
  subheader: string
  image: string
  downloadLink: string
  links: [
    {
      label: string
      url: string
    }
  ]
}

const HeroAngledImage: React.FC<Props> = ({
  headline,
  subheader,
  image,
  links,
  downloadLink,
}) => {
  const [showMobileNav, setShowMobileNav] = useState(false)
  return (
    <div className="">
      <div className="bg-vertical-primary-gradient text-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <nav className="flex items-center  md:py-4 lg:py-6 justify-between lg:justify-start hidden md:block">
              {links.map((l, index) => {
                return (
                  <a
                    href={l.url}
                    key={index}
                    className={classnames(
                      "font-medium text-gray-300 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out",
                      { "ml-8": index != 0 }
                    )}
                  >
                    {l.label}
                  </a>
                )
              })}
            </nav>
            <div className="flex block md:hidden">
              <div className="flex-grow"></div>
              <button
                type="button"
                onClick={() => setShowMobileNav(true)}
                className="mt-3 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center pb-12">
            <div className="lg:w-1/2 flex-1 w-full mb-8 lg:mb-0">
              <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none lg:text-6xl">
                {headline}
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                {subheader}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center md:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href={downloadLink}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-primary-base bg-white hover:bg-primary-dark shadow-lg hover:shadow-xl hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 cursor-pointer"
                  >
                    <FaAppStoreIos className="mr-3 text-3xl" />
                    Download GifJif
                  </a>
                </div>
              </div>
            </div>

            <div className="lg:w-1/2 flex-1 lg:flex-2 w-full">
              <img
                className="h-96 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                src={image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {showMobileNav && (
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md">
            <div className="rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="/img/logos/workflow-mark-on-white.svg"
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <button
                    onClick={() => setShowMobileNav(false)}
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
                {links.map((l, index) => {
                  return (
                    <a
                      href={l.url}
                      key={index}
                      className={classnames(
                        "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out",
                        { "mt-1": index != 0 }
                      )}
                    >
                      {l.label}
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default HeroAngledImage
