import React from "react"
import * as icons from "react-icons/fa"
type Props = {
  //Insert Props Here
  title: string
  subtitle: string
  keyword: string
  features: [
    {
      icon: string
      feature: string
      description: string
    }
  ]
  className?: string
  id?: string
}

const FeatureGrid: React.FC<Props> = ({
  title,
  subtitle,
  keyword,
  features,
  className,
  id,
}) => {
  return (
    <div className={"py-12 bg-white " + className} id={id}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="text-base leading-6 text-primary-base font-semibold tracking-wide uppercase">
            {keyword}
          </p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {title}
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            {subtitle}
          </p>
        </div>

        <div className="mt-10">
          <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            {features.map((f, index) => {
              return (
                <li key={index} className="mb-4 md:mb-0">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-base text-white">
                        {icons[f.icon] && React.createElement(icons[f.icon])}
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        {f.feature}
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        {f.description}
                      </p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FeatureGrid
